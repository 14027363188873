import { confluenceSessionStorageInstance as sessionStorage } from '@confluence/storage-manager';

type updateExpandedStateArgs = {
	isOpen: boolean;
	sectionKey: string;
	sessionStorageKey: string;
};

export const updateExpandedState = ({
	isOpen,
	sectionKey,
	sessionStorageKey,
}: updateExpandedStateArgs) => {
	const expandedStates = sessionStorage.getItem(sessionStorageKey);
	if (expandedStates) {
		expandedStates[sectionKey] = isOpen;
		sessionStorage.setItem(sessionStorageKey, {
			...expandedStates,
		});
	}
};
